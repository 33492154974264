import * as React from 'react';
import Layout from '../../components/common/Layout';
import MainContents from '../../components/common/MainContents';
import PageHeading from '../../components/ui/Pageheading';
import { Button } from '../../components/ui/Button';

const ContactThanksPage = () => (
    <Layout pageTitle="導入前 お問い合わせ | FDM フードデリバリーマネージャー">
      <MainContents>
        <PageHeading>導入前 お問い合わせ</PageHeading>
        <p className="mt-7.5 md:mt-20 text-sm font-medium tracking-wide leading-loose text-center">
          この度は、お問い合わせいただきまして<br />誠にありがとうございます。
        </p>
        <p className="mt-7.5 max-w-lg mx-auto md:px-1.5 text-sm tracking-wide leading-loose">
          内容確認後、担当より通常1〜3営業日以内にご連絡いたします。<br />
          土日祝日前後、年末年始等に関してはご連絡が遅れる場合がございます。
        </p>
        <p className="relative mt-5 mx-auto max-w-lg pl-4 text-xs tracking-wide leading-relaxed">
          <span className="absolute left-0">※</span>
          なお、ご入力いただいたメールアドレス宛に受付完了メールを自動送信しております。<br />
          届かない場合、迷惑メールフォルダに入っている、メールアドレスを間違えているなどの可能性がありますのでご確認ください。
        </p>
        <div className="mt-10 mx-auto md:pb-20 max-w-3xs text-center">
          <Button color="gray" size="xs" href="/" className="w-full">TOPに戻る</Button>
        </div>
      </MainContents>
    </Layout>
  );

export default ContactThanksPage;
