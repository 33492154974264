import * as React from 'react';

type MainContentsProps = {
  children: React.ReactNode;
};

const MainContents: React.VFC<MainContentsProps> = ({ children }) => (
  <div className="bg-pale">
    <div className="container bg-pale pt-10 pb-15 md:py-20">
      {children}
    </div>
  </div>
);

export default MainContents;
