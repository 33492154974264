import classNames from 'classnames';
import * as React from 'react';

type PageHeadingProps = {
  className?: string;
  children: React.ReactNode;
}

const PageHeading: React.VFC<PageHeadingProps> = ({className, children}) => (
    <h1 className={classNames('text-fdm-xl md:text-fdm-3xl font-medium tracking-wide leading-relaxed text-center', className)}>{children}</h1>
  );

export default PageHeading;